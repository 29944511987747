/*! =========================================================
 *
 Material Dashboard PRO React - v1.0.0 based on Material Dashboard PRO - V1.2.1
*
* =========================================================
*
* Copyright 2016 Creative Tim (http://www.creative-tim.com/product/material-dashboard-pro-react)
 *
 *                       _oo0oo_
 *                      o8888888o
 *                      88" . "88
 *                      (| -_- |)
 *                      0\  =  /0
 *                    ___/`---'\___
 *                  .' \|     |// '.
 *                 / \|||  :  |||// \
 *                / _||||| -:- |||||- \
 *               |   | \\  -  /// |   |
 *               | \_|  ''\---/''  |_/ |
 *               \  .-\__  '-'  ___/-. /
 *             ___'. .'  /--.--\  `. .'___
 *          ."" '<  `.___\_<|>_/___.' >' "".
 *         | | :  `- \`.;`\ _ /`;.`/ - ` : | |
 *         \  \ `_.   \_ __\ /__ _/   .-` /  /
 *     =====`-.____`.___ \_____/___.-`___.-'=====
 *                       `=---='
 *
 *     ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 *
 *               Buddha Bless:  "No Bugs"
 *
 * ========================================================= */

// variables and mixins
@import 'material-dashboard-pro-react/variables';
@import 'material-dashboard-pro-react/mixins';
@import 'material-dashboard-pro-react/shadows';

// plugin css
@import 'material-dashboard-pro-react/plugins/plugin-nouislider';
@import 'material-dashboard-pro-react/plugins/plugin-perfect-scrollbar';
@import 'material-dashboard-pro-react/plugins/plugin-react-datetime';
@import 'material-dashboard-pro-react/plugins/plugin-react-bootstrap-sweetalert';
@import 'material-dashboard-pro-react/plugins/plugin-react-chartist';
@import 'material-dashboard-pro-react/plugins/plugin-react-big-calendar';
@import 'material-dashboard-pro-react/plugins/plugin-react-jvectormap';
@import 'material-dashboard-pro-react/plugins/plugin-react-table';
@import 'material-dashboard-pro-react/plugins/plugin-react-tagsinput';

// Core CSS
@import 'material-dashboard-pro-react/misc';
@import 'material-dashboard-pro-react/fileupload';
