.frame {
  padding: 20px;
  margin-top: 20px;
  background: transparent !important;
}

.frameLabel {
  margin-top: -33px !important;
  margin-left: -10px !important;
  padding-left: 10px;
  padding-right: 10px;
  width: fit-content;
}