@import '@hlcr/mui/theme/mixins/breakpoints.scss';
@import '@hlcr/mui/theme/mixins/shadows.scss';
@import '@hlcr/mui/theme/mixins/images.scss';

.content {
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: 1fr auto;
	grid-gap: 40px;
	justify-items: center;

	padding-top: 10px; // we need that padding, else the image shadow will be cut off

	@include breakpoint-md {
		grid-template-columns: 1fr 1fr;
		grid-template-rows: 1fr;

		justify-items: start;

		.imagePlaceholder {
			@include image-placeholder(4, 3); // 4:3 aspect ratio
		}
	}

	.eventImage {
		max-width: 100%;
		max-height: 200px;

		border-radius: 6px;
		box-shadow: $image-shadow;

		justify-content: center;

		@include breakpoint-md {
			max-width: 100%;
			height: auto;
			max-height: 300px;
		}
	}

	.eventText {
		display: grid;
		justify-items: center;
		white-space: pre-line;

		@include breakpoint-md {
			display: block;
			justify-items: unset;
		}
	}
}

.tokenInput {
	display: flex;
	flex-direction: column;

	.label {
		margin-bottom: 20px;
	}
}
