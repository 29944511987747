@import "material-dashboard-pro/scss/material-dashboard-pro-react";

body {
	--hl-color-primary: #{$brand-primary};
	--hl-color-warning: #{$brand-warning};
	--hl-color-danger: #{$brand-danger};
	--hl-color-rose: #{$brand-rose};
	--hl-color-success: #{$brand-success};
	--hl-color-info: #{$brand-info};
	--hl-color-gray: #999999;

	--hl-level-color-novice: #00bcd4;
	--hl-level-color-easy: #4caf50;
	--hl-level-color-medium: #ff9800;
	--hl-level-color-hard: #e91e63;
	--hl-level-color-leet: #444444;

	--hl-secondary-text-color: #ccc;
}

body.hl--dark-mode {
	--hl-secondary-text-color: #999;
}

a {
	color: var(--hl-color-info);
}

.hl-markdown-html {
	blockquote {
		padding: 0 1em;
		color: var(--hl-secondary-text-color);
		border-left: .25em solid var(--hl-secondary-text-color);
		margin-left: 0;
	}

	pre {
		padding: 16px;
		overflow: auto;
		font-size: 85%;
		line-height: 1.45;
		background-color: rgba(0, 0, 0, .15);
		border-radius: 6px;
		white-space: break-spaces;
	}

	code, tt {
		padding: .2em .4em;
		margin: 0;
		font-size: 85%;
		white-space: break-spaces;
		background-color: rgba(0, 0, 0, .15);
		border-radius: 6px;
	}

	table {
		display: block;
		width: max-content;
		max-width: 100%;
		overflow: auto;
		margin-bottom: 16px;
		border-spacing: 0;
		border-collapse: collapse;

		th, td {
			padding: 6px 13px;
			border: 1px solid var(--hl-secondary-text-color);
		}
	}

	img {
		max-width: 100%;
		height: auto;
	}
}

.hl-bb-description-markdown {
	table {
		display: block;
		width: max-content;
		max-width: 100%;
		overflow: auto;
		margin-bottom: 16px;
		border-spacing: 0;
		border-collapse: collapse;

		tr:nth-child(2n) {
			background: rgba(0, 0, 0, 0.04);
		}

		th {
			background: rgba(0, 0, 0, 0.04);
		}

		th, td {
			text-align: left;
			padding: 6px 13px;
			//border: 1px solid var(--hl-secondary-text-color);
		}
	}
}
