.filters {
	display: flex;
	justify-content: flex-end;
}

.waitingForHunter {
	color: var(--hl-color-danger);
	font-weight: bold;
	font-size: 1.1em;
	margin: 0;
}

.statusFilter {
	display: flex;
	flex-direction: column;
}

.programNameCell {
	cursor: pointer;
}
