

.eventImage {
	max-width: 100%;
}

.eventDescription {
	padding-left: 20px;
	flex: 2;
}

.container {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: space-between;
}

.historyMarkdownLabel {
	font-size: .9em;
	margin-left: auto;
	margin-top: -15;
	width: fit-content;
}

.imageAndEventAttachments {
	flex: 1;
	max-width: 250px;
	flex-direction: column;
}

.attachments {
	margin-top: 20px;
	padding: 10px;

	li {
		border-bottom: 1px solid #CCC3;
		margin: 10px 0;
		list-style: none;
	}
}
