.historyMarkdownLabel {
	font-size: .9em;
	margin-left: auto;
	margin-top: -15;
	width: fit-content;
}

.attachments {
	margin-top: 20px;
	padding: 10px;

	li {
		border-bottom: 1px solid #CCC3;
		margin: 10px 0;
		list-style: none;
	}
}

.badgeContainer {
	text-align: right;
}

.infoBox {
	margin: 1rem 0;
}

.title {
	display: flex;
	align-items: center;
	font-weight: bold;
}

.chip {
	margin-right: 4px;
}

.uploadContainer {
	height: 100%;
	display: flex;
	flex-direction: column;
}

.upload {
	flex-grow: 1;
}

.uploadHandleBox {
	padding: .5rem;
	text-align: center;
	font-style: italic;
	background-color: gray;
	color: white;
	font-weight: bold;
}
