.selectContainer {
	$container-spacing: 60px;

	display: flex;
	align-items: flex-end;
	margin-bottom: 20px;

	> div {
		flex: 1;
	}

	.spacer {
		width: $container-spacing;
	}

	.solutionDownloadButton {
		margin-left: $container-spacing;
		height: 35px;
	}
}
