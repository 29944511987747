.actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.validFrom {
  color: var(--hl-secondary-text-color);
}

.reviewReason {
  margin-top: 20px;
  font-size: 0.8rem;
  color: var(--hl-secondary-text-color);
  padding-bottom: -40px;
  height: 100%;
  p {
    margin: 0;
  }
}