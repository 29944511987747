@use "sass:math";

@mixin image-placeholder($aspect-ratio-width, $aspect-ratio-height) {
	position: relative;
	width: 100%;
	height: 0;
	padding-top: #{math.div($aspect-ratio-height, $aspect-ratio-width) * 100%};
	display: flex;
	justify-content: center;

	img {
		position: absolute;
		top: 0;
	}
}
