// -----------------------------------------------------
// Breakpoints for different screen sizes
// -----------------------------------------------------

// Small tablets and large smartphones (landscape view)
$screen-sm-min: 450px;
// Small tablets (portrait view)
$screen-md-min: 788px;
// Tablets and small desktops
$screen-lg-min: 960px;
// Large tablets and desktops
$screen-xl-min: 1200px;

// Small devices
@mixin breakpoint-sm {
	@media (min-width: #{$screen-sm-min}) {
		@content;
	}
}

// Medium devices
@mixin breakpoint-md {
	@media (min-width: #{$screen-md-min}) {
		@content;
	}
}

// Large devices
@mixin breakpoint-lg {
	@media (min-width: #{$screen-lg-min}) {
		@content;
	}
}

// Extra large devices
@mixin breakpoint-xl {
	@media (min-width: #{$screen-xl-min}) {
		@content;
	}
}
