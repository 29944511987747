$cardsHorizontalSpacing: 30px;

.statIconSeparator {
  display: inline-block;
  width: 10px;
}

.cardContainer {
  margin-top: $cardsHorizontalSpacing;
}

.cardGridContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-top: -$cardsHorizontalSpacing;
}

.cardRedeemContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: stretch;
}

.cardRedeemButtons {
  display: flex;
  justify-content: center;
}

.cardRedeemInputForm {
  padding-top: 5px;
}

.title {
  font-weight: 500;
  color: #aaaaaa;
  font-size: 1.6em;
  margin-bottom: 10px;
}